import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Open',
    component: () => import(/* webpackChunkName: "about" */ '../views/open.vue'),
    meta:{title:'欢迎使用职业规划系统'}
  },
  {
    path: '/home',
    name: 'Introduction',
    component: () => import(/* webpackChunkName: "about" */ '../views/Introduction.vue'),
    meta:{title:'首页'}
  },
  {
    path: '/addReport',
    name: 'AddReport',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/addReport.vue'),
    meta:{title:'新增报告'}
  },
  {
    path: '/bindUser',
    name: 'bindUser',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/bindUser.vue'),
    meta:{title:'绑定已有账户'}
  },
  {
    path: '/careerResult',
    name: 'CareerResult',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/careerResult.vue'),
    meta:{title:'职业规划结果'}
  },
  {
    path: '/careerResultNopay',
    name: 'CareerResultNopay',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/careerResultNopay.vue'),
    meta:{title:'未支付的职业规划结果'}
  },
  {
    path: '/reportList',
    name: 'ReportList',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/reportList.vue'),
    meta:{title:'报告列表'}
  },
  {
    path: '/buyLession',
    name: 'buyLession',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/buyLession.vue'),
    meta:{title:'购买课程'}
  },
  {
    path: '/form',
    name: 'Form',
    component: () => import(/* webpackChunkName: "about" */ '../views/form.vue'),
    meta:{title:'喵星君大大预约表'}
  },
  {
    path: '/submitResult',
    name: 'SubmitResult',
    component: () => import(/* webpackChunkName: "about" */ '../views/submitResult.vue'),
    meta:{title:'喵星君大大预约表'}
  },
  {
    path: '/admin',
    name: 'Longin',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue'),
    meta:{title:'喵星君大大后台管理'}
  },
  {
    path: '/userQuery',
    name: 'UserQuery',
    component: () => import(/* webpackChunkName: "about" */ '../views/userQuery.vue'),
    meta:{title:'喵星君大大用户列表'}
  },
  {
    path: '/paipan',
    name: 'Paipan',
    component: () => import(/* webpackChunkName: "about" */ '../views/paipanSubmit.vue'),
    meta:{title:'喵星君大大四柱八字排盘'}
  },
  {
    path: '/paipanResult',
    name: 'PaipanResult',
    component: () => import(/* webpackChunkName: "about" */ '../views/paipanResult.vue'),
    meta:{title:'喵星君大大四柱八字排盘结果'}
  },
  {
    path: '/paipanRecord',
    name: 'PaipanRecord',
    component: () => import(/* webpackChunkName: "about" */ '../views/paipanRecord.vue'),
    meta:{title:'喵星君大大四柱八字排盘结果'}
  },
  {
    path: '/oalogin',
    name: 'oaLogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/oalogin.vue'),
    meta:{title:'oa登陆页'}
  },
  {
    path: '/oadashboard',
    name: 'oaDashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/oadashboard.vue'),
    meta:{title:'oa首页'}
  },
  {
    path: '/oaplan',
    name: 'oaPlan',
    component: () => import(/* webpackChunkName: "about" */ '../views/oaplan.vue'),
    meta:{title:'oa提交计划页'}
  },
  {
    path: '/oanotice',
    name: 'oaNotice',
    component: () => import(/* webpackChunkName: "about" */ '../views/oaNotice.vue'),
    meta:{title:'oa公告页'}
  },
  {
    path: '/oatask',
    name: 'oaTask',
    component: () => import(/* webpackChunkName: "about" */ '../views/oatask.vue'),
    meta:{title:'oa任务页'}
  },
  {
    path: '/oaflow',
    name: 'oaFlow',
    component: () => import(/* webpackChunkName: "about" */ '../views/oaflow.vue'),
    meta:{title:'oa流程页'}
  },
  {
    path: '/oauserlist',
    name: 'oaUserlist',
    component: () => import(/* webpackChunkName: "about" */ '../views/oauserlist.vue'),
    meta:{title:'oa用户列表页'}
  },
  {
    path: '/messageList',
    name: 'MessageList',
    component: () => import(/* webpackChunkName: "MessageList" */ '../views/messageList.vue'),
    meta:{title:'联系人列表'},
  },
  {
    path: '/messageDetail',
    name: 'MessageDetail',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/messageDetail.vue'),
    meta:{title:'对话'}
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/account.vue'),
    meta:{title:'记账'}
  },
  {
    path: '/typeinword',
    name: 'Typeinword',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/typeinword.vue'),
    meta:{title:'录入断语'}
  },
  {
    path: '/generateword',
    name: 'generateword',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/generateword.vue'),
    meta:{title:'断语生成'}
  },
  {
    path: '/userRegister',
    name: 'userRegister',
    component: () => import(/* webpackChunkName: "about" */ '../views/userRegister.vue'),
    meta:{title:'用户注册'}
  },
  // 爱三页面
  {
    path: '/loveThreeHome',
    name: 'loveThreeHome',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/loveThreeHome.vue'),
    meta:{title:'我的爱情观'}
  },
  {
    path: '/loveThreeMatchList',
    name: 'loveThreeMatchList',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/loveThreeMatchList.vue'),
    meta:{title:'匹配列表'}
  },
  {
    path: '/loveThreeMatchRes',
    name: 'loveThreeMatchRes',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/loveThreeMatchRes.vue'),
    meta:{title:'匹配结果'}
  },
  {
    path: '/loveThreeRegister',
    name: 'loveThreeRegister',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/loveThreeRegister.vue'),
    meta:{title:'用户注册'}
  },
  {
    path: '/personCenter',
    name: 'personCenter',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/personCenter.vue'),
    meta:{title:'个人中心'}
  },
  {
    path: '/reliableHome',
    name: 'reliableHome',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/reliableHome.vue'),
    meta:{title:'靠谱吗'}
  },
  {
    path: '/reliableRegister',
    name: 'reliableRegister',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/reliableRegister.vue'),
    meta:{title:'靠谱吗'}
  },
  {
    path: '/reliableList',
    name: 'reliableList',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/reliableList.vue'),
    meta:{title:'靠谱吗匹配记录'}
  },
  {
    path: '/reliableResult',
    name: 'reliableResult',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/reliableResult.vue'),
    meta:{title:'靠谱吗匹配结果'}
  },
  {
    path: '/home',
    name: 'Introduction',
    component: () => import(/* webpackChunkName: "about" */ '../views/Introduction.vue'),
    meta:{title:'首页'}
  },
  {
    path: '/gradeTest',
    name: 'GradeTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/gradeTest.vue'),
    meta:{title:'答题'}
  },
  {
    path: '/clientlogin',
    name: 'clientLogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/clientlogin.vue'),
    meta:{title:'客户登录页'}
  },
  {
    path: '/clienthome',
    name: 'clienthome',
    component: () => import(/* webpackChunkName: "about" */ '../views/clienthome.vue'),
    meta:{title:'客户主页'}
  },
  {
    path: '/lifeturn',
    name: 'lifeturn',
    component: () => import(/* webpackChunkName: "about" */ '../views/lifeturn.vue'),
    meta:{title:'转折点'}
  },
  {
    path: '/dailyluck',
    name: 'dailyluck',
    component: () => import(/* webpackChunkName: "about" */ '../views/dailyluck.vue'),
    meta:{title:'每日运势'}
  },
  {
    path: '/dailyluckback',
    name: 'dailyluckback',
    component: () => import(/* webpackChunkName: "about" */ '../views/dailyluckback.vue'),
    meta:{title:'每日运势'}
  },
  {
    path: '/dailyluckList',
    name: 'dailyluckList',
    component: () => import(/* webpackChunkName: "about" */ '../views/dailyluckList.vue'),
    meta:{title:'每日运势列表'}
  },
  {
    path: '/keyword',
    name: 'keyword',
    component: () => import(/* webpackChunkName: "about" */ '../views/keyword.vue'),
    meta:{title:'2023关键词'}
  },
  {
    path: '/code',
    name: 'code',
    component: () => import(/* webpackChunkName: "about" */ '../views/code.vue'),
    meta:{title:'自选'}
  },
  {
    path: '/qimensubmit',
    name: 'qimensubmit',
    component: () => import(/* webpackChunkName: "about" */ '../views/qimensubmit.vue'),
    meta:{title:'奇门排盘'}
  },
  {
    path: '/qimenresult',
    name: 'qimenresult',
    component: () => import(/* webpackChunkName: "about" */ '../views/qimenresult.vue'),
    meta:{title:'奇门排盘结果'}
  },
  {
    path: '/weiboHome',
    name: 'weiboHome',
    component: () => import(/* webpackChunkName: "about" */ '../views/weibo/weiboHome.vue'),
    meta:{title:'微博平台'}
  },
  {
    path: '/phoneNumber',
    name: 'phoneNumber',
    component: () => import(/* webpackChunkName: "about" */ '../views/phoneNumber.vue'),
    meta:{title:'手机号分析'}
  },
  {
    path: '/sw',
    name: 'sw',
    component: () => import(/* webpackChunkName: "about" */ '../views/sw.vue'),
    meta:{title:'松旺官网'}
  },
  // --------------
  // --------------
  // 微博平台下的路径
  // --------------
  // --------------
  {
    path: '/paipanWeibo',
    name: 'PaipanWeibo',
    component: () => import(/* webpackChunkName: "about" */ '../views/weibo/paipan/paipanSubmit.vue'),
    meta:{title:'喵星君大大四柱八字排盘'}
  },
  {
    path: '/paipanResultWeibo',
    name: 'PaipanResultWeibo',
    component: () => import(/* webpackChunkName: "about" */ '../views/weibo/paipan/paipanResult.vue'),
    meta:{title:'喵星君大大四柱八字排盘结果'}
  },
  {
    path: '/paipanRecordWeibo',
    name: 'PaipanRecordWeibo',
    component: () => import(/* webpackChunkName: "about" */ '../views/weibo/paipan/paipanRecord.vue'),
    meta:{title:'喵星君大大四柱八字排盘记录'}
  },
  {
    path: '/weibocareerhome',
    name: 'weibocareerhome',
    component: () => import(/* webpackChunkName: "about" */ '../views/weibo/career/Introduction.vue'),
    meta:{title:'职业规划首页'}
  },
  {
    path: '/weibocareeradd',
    name: 'weibocareeradd',
    component: () => import(/* webpackChunkName: "about" */ '../views/weibo/career/addReport.vue'),
    meta:{title:'新增职业规划'}
  },
  {
    path: '/weibocareerlist',
    name: 'weibocareerlist',
    component: () => import(/* webpackChunkName: "about" */ '../views/weibo/career/reportList.vue'),
    meta:{title:'职业规划列表'}
  },
  {
    path: '/weibocareernopay',
    name: 'weibocareernopay',
    component: () => import(/* webpackChunkName: "about" */ '../views/weibo/career/careerResultNopay.vue'),
    meta:{title:'职业规划未支付页'}
  },
  {
    path: '/careerAlipay',
    name: 'careerAlipay',
    component: () => import(/* webpackChunkName: "about" */ '../views/weibo/career/careerAlipay.vue'),
    meta:{title:'支付宝支付页'}
  },
  {
    path: '/weibocareerpay',
    name: 'weibocareerpay',
    component: () => import(/* webpackChunkName: "about" */ '../views/weibo/career/careerResult.vue'),
    meta:{title:'职业规划支付页'}
  },
  {
    path: '/weiboreliahome',
    name: 'weiboreliahome',
    component: () => import(/* webpackChunkName: "about" */ '../views/weibo/relia/reliableHome.vue'),
    meta:{title:'靠谱吗首页'}
  },
  {
    path: '/weibouserRegister',
    name: 'weibouserRegister',
    component: () => import(/* webpackChunkName: "about" */ '../views/weibo/relia/userRegister.vue'),
    meta:{title:'靠谱吗新用户注册'}
  },
  {
    path: '/weiboreliaregister',
    name: 'weiboreliaregister',
    component: () => import(/* webpackChunkName: "about" */ '../views/weibo/relia/reliableRegister.vue'),
    meta:{title:'靠谱吗注册页'}
  },
  {
    path: '/reliableAlipay',
    name: 'reliableAlipay',
    component: () => import(/* webpackChunkName: "about" */ '../views/weibo/relia/reliableAlipay.vue'),
    meta:{title:'支付宝支付页'}
  },
  {
    path: '/weiborelialist',
    name: 'weiborelialist',
    component: () => import(/* webpackChunkName: "about" */ '../views/weibo/relia/reliableList.vue'),
    meta:{title:'靠谱吗列表页'}
  },
  {
    path: '/planetHome',
    name: 'planetHome',
    component: () => import(/* webpackChunkName: "about" */ '../views/planetHome.vue'),
    meta:{title:'人际星图首页'}
  },
  {
    path: '/planet',
    name: 'planet',
    component: () => import(/* webpackChunkName: "about" */ '../views/planet.vue'),
    meta:{title:'人际星图主页'}
  },
  {
    path: '/planetList',
    name: 'planetList',
    component: () => import(/* webpackChunkName: "about" */ '../views/planetList.vue'),
    meta:{title:'人际星图列表'}
  },
  {
    path: '/weiboreliaresult',
    name: 'weiboreliaresult',
    component: () => import(/* webpackChunkName: "about" */ '../views/weibo/relia/reliableResult.vue'),
    meta:{title:'靠谱吗结果页'}
  },
  {
    path: '/article',
    name: 'article',
    component: () => import(/* webpackChunkName: "about" */ '../views/article.vue'),
    meta:{title:'文案评估'}
  },
  {
    path: '/articleResult',
    name: 'articleResult',
    component: () => import(/* webpackChunkName: "about" */ '../views/articleResult.vue'),
    meta:{title:'文案评估结果'}
  },
  // --------------
  // --------------
  // 我添加的路径
  // --------------
  // --------------
  {
    path: '/template',
    name: 'template',
    component: () => import(/* webpackChunkName: "about" */ '../views/template/template.vue'),
    meta:{title:'文案评估结果'}
  },
  // {
  //   path: '/codetest',
  //   name: 'codetest',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/codetest.vue'),
  //   meta:{title:'贪吃蛇测试'}
  // },
    // 404 page must be placed at the end !!!
    { path: '*', redirect: '/404', hidden: true }

]

// const createRouter = () => new Router({
//   // mode: 'history', // require service support
//   scrollBehavior: () => ({ y: 0 }),
//   routes: constantRoutes
// })

// const router = createRouter()

// // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
// export function resetRouter() {
//   const newRouter = createRouter()
//   router.matcher = newRouter.matcher // reset router
// }

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
