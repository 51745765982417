import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
Vue.use(Vuex)
const userinfoCareer =  Cookies.get('userinfoCareer') == 'undefined' || Cookies.get('userinfoCareer') == undefined  ? '{"us_id":"","us_name":"","us_headimgurl":"","us_openid":"","us_unionid":""}' : Cookies.get('userinfoCareer')
const userinfoLove =  Cookies.get('userinfoLove') == undefined || Cookies.get('userinfoCareer') == 'undefined' ? '{"jsopenid":"","unionid":"","nickname":"","image":""}' : Cookies.get('userinfoLove')
export default new Vuex.Store({
  state: {
    weiboid: Cookies.get('weiboid') || '',
    weiboname: Cookies.get('weiboname') || '',
    weiboimg: Cookies.get('weiboimg') || '',
    weibofoll: Cookies.get('weibofoll') || '',
    weibodomin: Cookies.get('weibodomin') || '',
    weibo: Cookies.get('weiboid') || '',
    uid: Cookies.get('uid') || '',
    openid:Cookies.get('openid') || '',
    unionid:Cookies.get('unionid') || '',
    headimg:Cookies.get('headimg') || '',
    name:Cookies.get('name') || '',
    meId:Cookies.get('meId') || '',
    otherId:Cookies.get('otherId') || '',
    code:Cookies.get('code') || '',
    // userinfoCareer: JSON.parse(Cookies.get('userinfoCareer') || '{"us_id":"","us_name":"","us_headimgurl":"","us_openid":"","us_unionid":""}'),
    userinfoCareer:JSON.parse(userinfoCareer),
    // userinfoLove: JSON.parse(Cookies.get('userinfoLove') || '{"jsopenid":"","unionid":"","nickname":"","image":""}')
    userinfoLove: JSON.parse(userinfoLove)
  },
  getters:{
    getweiboid(state){
      return state.weiboid;
    },
    getweiboname(state){
      return state.weiboname;
    },
    getweiboimg(state){
      return state.weiboimg;
    },
    getweibofoll(state){
      return state.weibofoll;
    },
    getweibodomin(state){
      return state.weibodomin;
    },
    getuid(state){
      return state.uid;
    },
    getopenid(state){
      return state.openid;
    },
    getunionid(state){
      return state.unionid;
    },
    getheadimg(state){
      return state.headimg;
    },
    getname(state){
      return state.name;
    },
    getmeId(state){
      return state.meId;
    },
    getotherId(state){
      return state.otherId;
    },
    getUserinfo(state) {
      return state.userinfo
    },
    getUserinfoLove(state) {
      return state.userinfoLove
    },
    getUserinfoCareer(state) {
      return state.userinfoCareer
    },
    getCode(state) {
      return state.code
    }
  },
  mutations: {
    setweiboid(state,param){
      state.weiboid = param;
      Cookies.set('weiboid', param, {expires: 1})
    },
    setweiboname(state,param){
      state.weiboname = param;
      Cookies.set('weiboname', param, {expires: 1})
    },
    setweiboimg(state,param){
      state.weiboimg = param;
      Cookies.set('weiboimg', param, {expires: 1})
    },
    setweibofoll(state,param){
      state.weibofoll = param;
      Cookies.set('weibofoll', param, {expires: 1})
    },
    setweibodomin(state,param){
      state.weibodomin = param;
      Cookies.set('weibodomin', param, {expires: 1})
    },
    setuid(state,param){
      state.uid = param;
      Cookies.set('uid', param, {expires: 1})
      // localStorage.setItem('uid', param)
    },
    setopenid(state,param){
      state.openid = param;
      Cookies.set('openid', param, {expires: 1})
      // localStorage.setItem('openid', param)
    },
    setunionid(state,param){
      state.unionid = param;
      Cookies.set('unionid', param, {expires: 1})
      // localStorage.setItem('unionid', param)
    },
    setheadimg(state,param){
      state.headimg = param;
      Cookies.set('headimg', param, {expires: 1})
      // localStorage.setItem('headimg', param)
    },
    setname(state,param){
      state.name = param;
      Cookies.set('name', param, {expires: 1})
      // localStorage.setItem('name', param)
    },
    setmeId(state,param){
      state.meId = param;
      Cookies.set('meId', param, {expires: 1})
      // localStorage.setItem('name', param)
    },
    setotherId(state,param){
      state.otherId = param;
      Cookies.set('otherId', param, {expires: 1})
      // localStorage.setItem('name', param)
    },
    setUserInfo(state, data) {
      state.userinfo = data;
      Cookies.set('userinfo', JSON.stringify(data), {expires: 1})
    },
    setUserInfoLove(state, data) {
      state.userinfoLove = data;
      Cookies.set('userinfoLove', JSON.stringify(data), {expires: 1})
    },
    setUserInfoCareer(state, data) {
      state.userinfoCareer = data;
      Cookies.set('userinfoCareer', JSON.stringify(data), {expires: 1})
    },
    setCode(state, data) {
      state.code = data;
      Cookies.set('code', data)
    }
  },
  actions: {
        // name就是调用此方法时要传的参数
     setuidval({commit,state}, param){
          // 跟后台打交道
          // 调用mutaions里面的方法
          commit("setuid", param);
      },
      setopenidval({commit,state}, param){
        // 跟后台打交道
        // 调用mutaions里面的方法
        commit("setopenid", param);
      },
      setunionidval({commit,state}, param){
        // 跟后台打交道
        // 调用mutaions里面的方法
         commit("setunionid", param);
      },
      setheadimgval({commit,state}, param){
        // 跟后台打交道
        // 调用mutaions里面的方法
         commit("setheadimg", param);
      },
      setnameval({commit,state}, param){
        // 跟后台打交道
        // 调用mutaions里面的方法
         commit("setname", param);
      },
      setmeIdval({commit,state}, param){
        // 跟后台打交道
        // 调用mutaions里面的方法
         commit("setmeId", param);
      },
      setotherIdval({commit,state}, param){
        // 跟后台打交道
        // 调用mutaions里面的方法
         commit("setotherId", param);
      }
  },
  modules: {
  }
})
